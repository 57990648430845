<template>
    <div class="printer-container">

        <div class="header">
            <div style="width: 205px"><logotop></logotop></div>
            <div class="header-right">
                <a-button class="back-btn bgBlue" size="large" type="primary" @click="$router.back()">
                    <a-icon type="arrow-left" /> 返回
                </a-button>
                <a-button class="back-btn bgDot" size="large" type="primary" @click="saveStorsge" style="float:right">
                   保存
                </a-button>
            </div>
        </div>
        <div class="content-view">
            <div class="printer-icon-view">
                <div class="title">选择打印机</div>
                <img class="printer-icon" src="@/assets/printer_icon.png">

                <a-button  icon="download" style="margin-top: 32px" type="dashed" class="plugin-down-btn" @click="downAction(1)">打印插件32位</a-button>
                <a-button  icon="download" style="margin-top: 32px" type="dashed" class="plugin-down-btn" @click="downAction(2)">打印插件64位</a-button>
            </div>
            <div class="printer-list" v-if="isHasLodop">
                <a-row :gutter="[54,31]" class="printer-list-view">
                    <a-col :span="12" v-for="(item, index) in printlist">
                        <a-button class="printer-item" @click="printerChoose(index)">
                            <div class="choose-icon" :class="{'printer-active': index === printerIndex}"/>
                            <div class="printer-name">{{item}}</div>
                        </a-button>
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
  import logotop from "@/components/logotop"; //顶部logo
  import {getLodop} from '@/assets/js/CLodopFuncs'
  export default {
    name: "printer",
  components: {
    logotop,
  },
    data() {
      return {
        printerIndex: 0,
        printlist: [],
        isHasLodop: true
      }
    },
    created() {
      this.isHasLodop = this.lodopHasIf();
      if (this.isHasLodop) {
        this.demoCreatePrinterList()
      }

    },
    methods: {
      lodopHasIf(){
        var LODOP = getLodop();
        try {
          if (LODOP.VERSION) {
            if (LODOP.CVERSION)
              console.log("当前有C-Lodop云打印可用!\n C-Lodop版本:" + LODOP.CVERSION + "(内含Lodop" + LODOP.VERSION + ")");
            else{
              console.log("本机已成功安装了Lodop控件！\n 版本号:" + LODOP.VERSION);
            }
            return true;
          } else {
            this.visible=true;
            return false;
          }
        } catch (err) {
          this.visible=true;
          return false;
        }
      },
      demoCreatePrinterList(){
          console.log('这是执行的')
        var LODOP = getLodop();
        let count = LODOP.GET_PRINTER_COUNT();
        var list = []
        let that = this;
       for (let i = 0; i < count; i++) {
         //根据设备序号获取设备名
         let msg = LODOP.GET_PRINTER_NAME(i);
         list.push(msg)
       }
       that.printlist = list;
       
       list.forEach((value, index) => {
         console.log(value)
         if (value === localStorage.getItem('printox')) {
           that.printerIndex = index
         }
       })

      },
      printerChoose(index) {
        this.printerIndex = index;

        // localStorage.setItem('printox', this.printlist[index]);

      },
      saveStorsge(){
           localStorage.setItem('printox', this.printlist[this.printerIndex]);
           this.$message.success('保存成功!')
      },
      downAction(type) {
        let url = ''
        if (type === 1) {
          url = 'https://pamd-mini-pages.oss-cn-beijing.aliyuncs.com/CLodop_Setup_for_Win32NT.exe'
        } else {
          url = 'https://pamd-mini-pages.oss-cn-beijing.aliyuncs.com/CLodop_Setup_for_Win64NT_4.096Extend.zip'
        }
        window.location.href = url
      }

    },

  }
</script>

<style scoped lang="less">
    .printer-container {
        width: 100%;
        height: 100%;
        padding: 10px;
        background: #F1F2F2;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .header {
            display: flex;
            width: 100%;
            .header-right {
                background-color: #fff;
                flex: 1;
                display: flex;
                 padding:0px 10px;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                .back-btn{
                    width:130px;
                    margin-left: 25px;
                }
                .bgBlue{
                    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
                    border-radius: 5px;
                    border:none;
                    height:80px;
                    outline:none;
                }
                .bgDot{
                     width:132px;
                    border:1px solid #2D54DC;
                    background: #fff;
                    border-radius: 5px;
                    color:#2D54DC;
                    // border:none;
                    height:80px;
                    outline:none;
                }

            }
        }
        .content-view {
            padding-right: 40px;
            padding-left: 58px;

            overflow: hidden;
            flex: 1;
            display: flex;
            .printer-icon-view {

                width: 324px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .title {
                    font-size: 30px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #000000;
                    margin-bottom:12px;
                }
                .printer-icon {
                    width: 100%;
                    height: 292px;
                }

                .plugin-down-btn {
                    width: 299px;
                    height: 70px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 11px 0px rgba(177, 188, 199, 0.15);
                    font-size: 23px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                }
            }
            .printer-list {
                margin-left: 121px;
                padding-top: 55px;
                padding-bottom: 55px;
                height: 100%;
                flex: 1;
                .printer-list-view {
                    height: 100%;

                    overflow-y: scroll;
                }
                .printer-item {
                    width: 100%;
                    height: 80px;
                    background: #FFFFFF;
                    border-radius: 5px;
                    border: 1px solid #C6CBD4;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .choose-icon {
                        width: 23px;
                        height: 23px;
                        border: 2px solid #B2B2B2;
                        border-radius: 23px;
                        &.printer-active {
                            border: none;
                            background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
                        }
                    }
                    .printer-name {
                        text-align: left;
                        width: calc(100% - 53px);
                        height: 100%;
                        display: flex;
                        align-items: center;
                        word-break: break-all;
                        white-space: pre-wrap;
                        /*justify-content: center;*/
                        /*white-space: nowrap;*/
                        /*overflow: hidden;*/
                        /*text-overflow: ellipsis;*/

                        margin-left: 30px;
                        font-size: 23px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #000000;
                    }
                }
            }
        }
    }
</style>